import React from "react";

class Conveyor extends React.Component {
  render() {
    return (
      <div>
        <p>Conveyor is an Automated Multi-Availabilty Zone Deployment tool </p>
        <p>Contributions: Improved Testing time from an Hour to 20mins, Improved Deployment of Conveyor time from an Hour to 25mins</p>
      </div>
    )
  }
}

export default Conveyor;
