import React from "react";

class AnubisProductsAndPeople extends React.Component {
  render() {
    return (
      <div>
        <p>Anubis Products and People is written in GO and is a resource look up Rest API</p>
      </div>
    )
  }
}

export default AnubisProductsAndPeople;
