import React from "react";

class Anubis extends React.Component {
  render() {
    return (
      <div>
        <p>Anubis is Product written in GO that acts as a Proxy layer to make an existing security layer more Highly Available</p>
      </div>
    )
  }
}

export default Anubis;
