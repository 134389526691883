import React from "react";

class Symphony extends React.Component {
  render() {
    return (
      <div>
        <p>Rundeck is an open Source tool, that we have deployed out into our internal Space.</p>
      </div>
    )
  }
}

export default Symphony;
