import React from "react";

class Vision extends React.Component {
  render() {
    return (
      <div>
        <p>Vision is a Distributed Tracing tool</p>
        <p>It supported Java and Javascript</p>
        <p>The ETL part was developed in GO</p>
      </div>
    )
  }
}

export default Vision;
